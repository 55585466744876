import { IChain } from "../types/chaintype";
import { NETWORK_TYPE } from "../configs";

export const chains: { sapphire_devnet: IChain[], sapphire_mainnet: IChain[] } = {
  sapphire_devnet: [{
    chainId: 80001,
    name: 'Matic Mumbai',
    currency: 'MATIC',
    decimals: 18,
    oaName: 'maticmum',
    type: 'testnet',
    rpcUrl: 'https://empty-wiser-shard.matic-testnet.quiknode.pro/4aed3d5d7f81acefb9154011e8be781b42dbf71c/',
    explorerUrl: 'https://mumbai.polygonscan.com',
  }, 
   {
     chainId: 11155111,
     name: "Sepolia",
     currency: "ETH",
     decimals: 18,
     oaName: "sepolia",
     type: "testnet",
     rpcUrl: "https://eth-sepolia.g.alchemy.com/v2/ci9nXk7T-Q6MOisxInAZrjyYW-yTnt9U",
     explorerUrl: "https://sepolia.etherscan.io",
  }, 
   {
    chainId: 51,
    name: "XDC Network",
    currency: "XDC",
    decimals: 18,
    oaName: "xdcapothem",
    type: "testnet",
    rpcUrl: "https://rpc.ankr.com/xdc_testnet/9fcd48a40fef9fa8eee3cbffdc50c6c5e509d96a75fe304aa7af92cb7995eb47",
    explorerUrl: "https://xdcscan.io",
  }, ],
  sapphire_mainnet:[{
    chainId: 1,
    name: "Ethereum",
    currency: "ETH",
    decimals: 18,
    oaName: "mainnet",
    type: "mainnet",
    rpcUrl: "https://late-greatest-sunset.quiknode.pro/7c7d9a06b5bdb44bacf8b35aec3eca9ad3cf6517",
    explorerUrl: "https://etherscan.io",
  },
  {
    chainId: 50,
    name: "XDC Network",
    currency: "XDC",
    decimals: 18,
    oaName: "xdc",
    type: "mainnet",
    rpcUrl: "https://rpc.ankr.com/xdc/9fcd48a40fef9fa8eee3cbffdc50c6c5e509d96a75fe304aa7af92cb7995eb47",
    explorerUrl: "https://xdcscan.io",
  },
  {
    chainId: 137,
    name: "Polygon Mainnet",
    currency: "POL",
    decimals: 18,
    oaName: "matic",
    type: "mainnet",
    rpcUrl: "https://quiet-hardworking-valley.matic.quiknode.pro/59646f682ee5253f9777845eb3e134b278f86125",
    explorerUrl: "https://polygonscan.com/",
    },
    {
      chainId: 101010,
      name: "Stability",
      currency: "FREE",
      oaName: "stability",
      type: "mainnet",
      decimals: 18,
      rpcUrl: "https://rpc.stabilityprotocol.com/zgt/0u7eysyltauu",
      explorerUrl: "https://stability.blockscout.com/",
  },
  {
      chainId: 295,
      name: "Hedera Mainnet",
      currency: "HBAR",
      oaName: "hederamainnet",
      type: "mainnet",
      decimals: 8,
      rpcUrl: "https://mainnet.hashio.io/api",
      explorerUrl: "https://hashscan.io/mainnet/",
  },
  ]
} 


export const chainList = chains[NETWORK_TYPE]