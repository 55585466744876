import React, { useState } from "react";
import logo from "./assets/logo.svg";

const Navbar = () => {
  const [isOpen, setIsOpen] = useState(false);
  const genericHamburgerLine = `h-0.5 w-7 my-1 rounded-full bg-[#082f7a] transition ease transform duration-300`;

  return (
    <div className="w-full h-full pt-2.5 px-5 flex flex-col justify-center items-center z-50">
      <div className="self-stretch px-6 py-[19px] bg-white rounded-xl justify-center items-center gap-8 inline-flex relative">
        <div className="flex-1 justify-start items-start flex">
          <a href="https://www.blockpeer.finance/"> <img src={logo} alt="logo" className="w-[10.5rem]" /></a>
        </div>
        {/* desktop menu */}
        <div className="justify-start items-start gap-[36px] hidden md:flex">
          <div className="nav-items"><a href="https://www.blockpeer.finance/blockpeer-trade">Blockpeer Trade</a></div>
          <div className="nav-items"><a href="https://www.blockpeer.finance/blockpeer-cliq">Blockpeer Cliq</a></div>
          <div className="nav-items"><a href="https://www.blockpeer.finance/blockpeer-books">Blockpeer Books</a></div>
          <div className="nav-items"><a href="https://www.blockpeer.finance/pricing">Pricing</a></div>
        </div>
        {/* mobile menu */}
        <div
          className={`absolute top-0 left-0 p-4 w-full bg-white rounded-lg transform transition-transform duration-500 md:hidden ${
            isOpen ? 'translate-y-24' : '-translate-y-full -mt-10'
          }`}
        >
          <div className="flex flex-col gap-y-5 justify-start mt-2 px-0 items-start ">
            <div className="nav-items"><a href="https://www.blockpeer.finance/blockpeer-trade">Blockpeer Trade</a></div>
            <div className="nav-items"><a href="https://www.blockpeer.finance/blockpeer-cliq">Blockpeer Cliq</a></div>
            <div className="nav-items"><a href="https://www.blockpeer.finance/blockpeer-books">Blockpeer Books</a></div>
            <div className="nav-items"><a href="https://www.blockpeer.finance/pricing">Pricing</a></div>
            <a href="https://app.blockpeer.finance/register/en" className="btn w-full bg-[#082F7A] text-white font-light text-lg">Start for Free</a>
          </div>
        </div>
        <div className="flex-1 flex-col justify-center items-end hidden md:flex">
          <a href="https://app.blockpeer.finance/register/en" className="bg-[#082F7A] text-white text-lg font-[300] rounded-md py-[9px] px-[26px]">Start for Free</a>
        </div>
        <button
          className="flex flex-col h-12 w-12 rounded justify-center items-center group md:hidden"
          onClick={() => setIsOpen(!isOpen)}
        >
          <div
            className={`${genericHamburgerLine} ${
              isOpen
                ? "-rotate-45 translate-y-1 opacity-50 group-hover:opacity-100"
                : "opacity-50 group-hover:opacity-100"
            }`}
          />
          <div
            className={`${genericHamburgerLine} ${
              isOpen
                ? "rotate-45 -translate-y-1.5 opacity-50 group-hover:opacity-100"
                : "opacity-50 group-hover:opacity-100"
            }`}
          />
        </button>
      </div>
    </div>
  );
};

export default Navbar;
