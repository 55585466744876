import { useCallback, useRef, useState } from "react";
import {
  isValid,
  openAttestationVerifiers,
  verificationBuilder,
} from "@tradetrust-tt/tt-verify";
import { useDropzone } from "react-dropzone";
import Loader from "./Loader";
import { getData } from "@govtechsg/open-attestation";
import { IframeApp } from "./IframeApp";
import InvalidDocument from "./InvalidDocument";
import ValidDocument from "./ValidDocument";
import DocInfo from "./DocInfo";
import cloudUploadImg from "./shared/assets/cloud-upload.png";
import checkClipboard from "./shared/assets/check-clipboard.png";
import { cn } from "../utils/cn";
import useCurrentChain from "../app/hooks/useChain";
import useAsyncEffect from "use-async-effect";
import { useWeb3Modal, useWeb3ModalProvider, useWeb3ModalAccount } from '@web3modal/ethers5/react'
import clsx from "clsx";
import { providers, Signer, ethers } from "ethers";
import { PDFDocument, StandardFonts, rgb } from 'pdf-lib';

const FileReaderComponent = () => {
  const [loading, setLoading] = useState(false);
  const [isValidIntegrity, setIsValidIntegrity] = useState(false);
  const [isValidDocumentStatus, setIsValidDocumentStatus] = useState(false);
  const [isValidIssuer, setIsValidIssuer] = useState(false);
  const [isValidDoc, setIsValidDoc] = useState(false);
  const [isDocChecked, setIsDocChecked] = useState(false);
  const [issuerLocation, setIssuerLocation] = useState("");
  const [unwrappedData, setUnwrappedData] = useState<any>(null);
  const [encryptedData, setEncryptedDocument] = useState<any>(null);
  const [tokenId, setTokenId] = useState("");
  const [tokenRegistry, setTokenRegistry] = useState(
    ""
  );

  const [signer, setSigner] = useState<Signer | null>(null);
  
  const {currentChain} = useCurrentChain();
  const { open } = useWeb3Modal();
  
  const { address, isConnected } = useWeb3ModalAccount();
  const { walletProvider } = useWeb3ModalProvider();
  const providerRef = useRef < providers.Provider | null>(null);

  useAsyncEffect(async() => {
    console.log('current-chain: ', currentChain);
    if (walletProvider) {
      const _provider = new ethers.providers.Web3Provider(walletProvider);
      providerRef.current = _provider;
      const _signer = await _provider.getSigner();
      setSigner(_signer);
    }
  }
  , [currentChain]);
  
  
  const onVerify = async (wrappedDocument: any) => {
    try {
      setLoading(true);
      setIsDocChecked(false);
  
      console.log('current-network: ',await providerRef.current?.getNetwork())
      if(!providerRef.current) throw new Error('Provider not found!');
      const verifier = verificationBuilder(openAttestationVerifiers, {
        provider: providerRef.current as any,
      });
      
      const fragments = await verifier(wrappedDocument as any);
      console.log("fragments: ", fragments);
      
      if (!isValid(fragments)) {
        setIsValidDoc(false);
        return;
      } else {
        setIsValidDoc(true);
      }
      if (isValid(fragments, ["DOCUMENT_INTEGRITY"])) {
        setIsValidIntegrity(true);
      }
      if (isValid(fragments, ["DOCUMENT_STATUS"])) {
        setIsValidDocumentStatus(true);
      }
      if (isValid(fragments, ["ISSUER_IDENTITY"])) {
        setIsValidIssuer(true);
      }

      /* console.log(isValid(fragments, ["DOCUMENT_INTEGRITY"])); // output true
      console.log(isValid(fragments, ["DOCUMENT_STATUS"])); // output false
      console.log(isValid(fragments, ["ISSUER_IDENTITY"])); // outpute false */
      readDocData(wrappedDocument);
    } catch (error) {
      console.error(error);
      setIsValidDoc(false);
    } finally {
      setIsDocChecked(true);
      setLoading(false);
    }
  };
  const readDocData = async (wrappedDocument: any) => {
    //console.log("wrappedDocument: ", wrappedDocument);
    //const ethersProvider = new ethers.providers.Web3Provider(provider!);
    //const _signer = await ethersProvider.getSigner();
    //console.log("_signer: ", _signer);
    //console.log('addr: ', await _signer.getAddress());

    try {
      const unwrapped_data = await getData(wrappedDocument);
      setUnwrappedData(unwrapped_data);
      //console.log("unwrapped_data: ", unwrapped_data);
      const issuer: any = unwrapped_data.issuers[0];
      //console.log("issuer: ", issuer);

      const targetHash = wrappedDocument.signature.targetHash;
      console.log("targetHash: ", targetHash);
      setTokenId("0x"+targetHash);
      setIssuerLocation(issuer.identityProof?.location!);
      setTokenRegistry(issuer.tokenRegistry);
    } catch (error) {
      console.log(error);
    }
  };
  const onDrop = useCallback(async (acceptedFiles: any) => {
    // Do something with the files
    handleReset();
    console.log(acceptedFiles);
    const file = acceptedFiles[0];

    if (file && file.name.endsWith(".tt") || file.name.endsWith(".pdf")) {
      const reader = new FileReader();
      if(file.name.endsWith(".pdf")) {
        const pdfDoc = await PDFDocument.load(await file.arrayBuffer());
        console.log('Title:', pdfDoc.getTitle())
        console.log('Author:', pdfDoc.getAuthor())
        console.log('Subject:', JSON.parse(pdfDoc.getSubject()!))
        console.log('Creator:', pdfDoc.getCreator())
        onVerify(JSON.parse(pdfDoc.getSubject()!));
        return;
      }
      reader.readAsText(file);
      reader.onload = (e) => {
        const encryptedDocument = e.target?.result as any;
        setEncryptedDocument(encryptedDocument);
        onVerify(JSON.parse(encryptedDocument));
      };

    } else {
      alert("Please select a valid .tt file.");
    }
  }, []);

  const { getRootProps, getInputProps, isDragActive } = useDropzone({
    onDrop,
    maxFiles: 1,
    accept: {
      "text/html": [".tt", ".tt", ".pdf"],
    },
  });
  
  const handleReset = () => {
    setIsValidDoc(false);
    setUnwrappedData(null);
    setIsValidIssuer(false);
    setIsDocChecked(false);
    setIsValidDocumentStatus(false);
    setIsValidIntegrity(false);
    setIssuerLocation("");
    setTokenRegistry("");
  };

  const formattedAddress = (address: string) => {
     return address?.slice(0, 5) + "..." + address?.slice(-5);
  };
  
  return (
    <div
      className={cn(
        "w-full inter flex rounded-3xl p-4 lg:py-16 lg:px-5 flex-col items-center gap-5 lg:gap-11 bg-gradient-to-b from-white border border-black",
        {
          "border-none bg-none": unwrappedData,
        }
      )}
    >
     
      {!unwrappedData && (
        <div className="flex inter text-start flex-col lg:flex-row justify-between items-center md:space-x-10 gap-4 text-sm w-full">
          <h2 className="text-3xl font-serif text-blue-900 font-light text-left inter">Verify your documents on</h2>
          <div className="flex items-center gap-4">
            {isConnected && <button className="btn rounded-lg btn-sm inter" onClick={() => open({ view: 'Networks' })}>Switch Network</button>}
            <button className={clsx("btn rounded-lg btn-sm px-5 btn-primary", isConnected ? 'btn-outline btn-info':'')} onClick={() => open()}>{isConnected ? formattedAddress(address!) : 'Connect Wallet'}</button>
          </div>
        </div>
      )}

      {unwrappedData && isValidDoc && (
        <ValidDocument
          issuer={issuerLocation}
          isValidDocStatus={isValidDocumentStatus}
          isValidIntegrity={isValidIntegrity}
          isValidIssuer={isValidIssuer}
        />
      )}

      {tokenRegistry && unwrappedData && (
        <DocInfo
          signer={signer!}
          provider={providerRef.current!}
          currentChain={currentChain!}
          tokenRegistryAddress={tokenRegistry}
          walletAddress={address?.toLocaleLowerCase()!}
          tokenId={tokenId}
        />
      )}

      {unwrappedData && (
        <div className="flex items-center justify-end ml-auto">
          <button
            className=" bg-blue-900 inter text-xl lg:text-3xl text-white rounded-2xl py-2 px-3 lg:py-4 lg:px-5 hover:bg-blue-950"
            onClick={handleReset}
          >
            Upload Another file
          </button>
        </div>
      )}
      {(!unwrappedData || !isValidDoc) && (
        <div className={clsx('flex flex-col lg:flex-row gap-20 w-full', !isConnected && 'pointer-events-none')}>
          <div className="rounded-full self-start p-16 bg-gradient-purple-blue">
            <img src={cloudUploadImg} width={400} height={500} alt="Cloud Upload" />
          </div>
          <div
            {...getRootProps()}
            className="flex-1 self-stretch flex gap-24 text-center flex-col items-center"
          >
            <div className="mt-20 relative flex flex-col justify-center items-center border border-[#3D2B91] bg-white rounded-[50%] w-full h-[270px] max-w-[380px]">
              <img
                src={checkClipboard}
                height={200}
                width={200}
                alt="clipboard with a checkmark in middle"
                className="absolute bottom-1/2"
              />
              <span className="inter text-center text-sm lg:text-md translate-y-full leading-2">
                Drag the document over to see an example <br /> of Blockpeer
                verify document feature
              </span>
            </div>
            {!isValidDoc && isDocChecked && (
              <InvalidDocument
                msg={
                  !isValidIntegrity
                    ? "Document integrity has been breached!"
                    : !isValidIssuer
                      ? "Document issuer identity is invalid!"
                      : "Document status is invalid!"
                }
              />
            )}
            {loading ? (
              <Loader />
            ) : (
              <>
                {/* <CloudArrowUpIcon className="w-10 h-10" /> */}
                <input {...getInputProps()} />
              </>
            )}
            {isDragActive ? (
              <p className="inter">Drop the files here ...</p>
            ) : (
                <div className="text-[27px] font-light  flex flex-col items-center self-stretch gap-6">
                 {!isConnected && <p className="text-error font-semibold text-xl m-4">Please connect your wallet first!</p>}
                <p className="text-center leading-10">Drop your files to verify its contents</p>
                <p>Or</p>
                <button className="btn inter bg-[#082F7A] text-white rounded-lg text-lg font-light">
                  Select Documents
                </button>
              </div>
            )}
          </div>
        </div>
      )}

      {unwrappedData && (
        <div className="w-full">
          <IframeApp documents={[{ name: "", document: unwrappedData }]} />
        </div>
      )}
    </div>
  );
};

export default FileReaderComponent;
