import React from 'react'
import dots from "./assets/dots.svg";
import ellipses from "./assets/ellipse.svg";

const JoinUs = () => {
  return (
    <div className="bg-slate-900 rounded-xl py-16 px-3 relative my-10 z-20">
      <img className='absolute w-full md:w-2/3 md:left-56 z-20 -top-0' src={dots} alt="dots" />
      <div className='absolute w-full z-20 top-0 left-0 rounded-full h-full'>
        <img className='w-full h-full object-cover border-none' src={ellipses} alt="ellipses" />
      </div>
      <div className='flex flex-col md:space-y-28 items-center px-3 relative mb-12'>
              <div className="relative flex-col justify-start items-center gap-10 flex z-20">
                  <div className="md:h-36 flex-col justify-start items-center gap-6 flex">
                    <div className="text-3xl font-bold md:text-5xl md:font-normal text-center leading-8 text-white">Ready to Simplify Trade and Finance?</div>
                    <div className="text-center font-light text-md md:text-xl text-white">Sign up for a free trial and discover how <span className='font-bold'>BlockPeer</span> can help you seamlessly <br /> integrate <span className='font-bold'>digital asset management</span>, <span className='font-bold'>trade documentation</span>, and <span className='font-bold'>financing</span> <br /> all in one platform.</div>
                  </div>
              </div>
          <a href='https://app.blockpeer.finance/register/en' className="z-50 mt-5 btn btn-wide text-slate-900 text-base font-normal font-['Inter'] leading-normal hover:text-blue-600">Book a demo</a>
      </div>
    </div>
  )
}

export default JoinUs