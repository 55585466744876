import "./App.css";
import FileReaderComponent from "./components/FileReaderComponent";
import Navbar from "./components/shared/Navbar";
import Footer from "./components/shared/Footer";
import { createWeb3Modal, defaultConfig } from "@web3modal/ethers5/react";
import { chainList } from "./constants/chainlist";
import JoinUs from "./components/shared/JoinUs";

function App() {
  // 1. Get projectId at https://cloud.walletconnect.com
  const projectId = process.env.REACT_APP_WC_PROJECT_ID!;

  // 3. Create modal
  const metadata = {
    name: "Blockpeer Verifier",
    description: "Blockpeer finance document verifier",
    url: "https://verify.blockpeer.finance", // origin must match your domain & subdomain
    icons: [
      "https://res.cloudinary.com/dligdeoxo/image/upload/v1709214271/ecj4bkieyxrm2r9eujyn.png",
    ],
  };

  createWeb3Modal({
    ethersConfig: defaultConfig({
      metadata,
      defaultChainId: 1,
      enableEIP6963: true,
      enableInjected: true,
      enableCoinbase: true,
      rpcUrl: "...", // used for the Coinbase SDK
    }),
    chains: [...chainList],
    projectId,
    enableAnalytics: true, // Optional - defaults to your Cloud configuration
  });

  return (
    <div className="md:px-16 w-full bg-[#f5f8fe] pb-10 relative m-0">
      <div className="fixed md:px-10 w-full top-0 left-0 z-50">
        <Navbar />
      </div>
      <div className="h-6"></div>
      <main className="pt-20 md:px-14 px-5 md:mt-20 md:py-10 mt-4 text-blue-900">
        
          <FileReaderComponent />
        
      </main>
      <div className="md:px-14 px-5">
        <JoinUs />
        <Footer />
      </div>
    </div>
  );
}
export default App;

/*  trade trust supported chains
    CHAIN_ID["local"] = "1337";
    CHAIN_ID["mainnet"] = "1";
    CHAIN_ID["matic"] = "137";
    CHAIN_ID["maticmum"] = "80001";
    CHAIN_ID["sepolia"] = "11155111";
    CHAIN_ID["xdc"] = "50";
    CHAIN_ID["xdcapothem"] = "51";
    CHAIN_ID["stability"] = "101010";
    CHAIN_ID["hederamainnet"] = "295";
*/
